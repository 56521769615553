import React from "react"
import Layout from "../components/_App/layout"
import Seo from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import MilestonesAchievedSoFar from "../components/Common/MilestonesAchievedSoFar"
import Partner from "../components/Common/Partner"
import Footer from "../components/_App/Footer"
import PageBanner from "../components/Common/PageBanner"

import AboutOne from "../assets/images/about-one.png"

const About = () => (
  <Layout>
    <Seo title="About Us" />

    <Navbar />

    {/*<PageBanner pageTitle="About Us" />*/}

    <div className="about-area pt-120-b-80">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-image">
              <img src={AboutOne} alt="about" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="section-title">
                <h1>About Us</h1>
                <div className="bar"/>
                <p>
                  A company founded back in 2019 by two visionary developers to
                  create and provide you with the best results possible. We are
                  a web developing company with great vision and plans for the
                  future to be a part of others success. We understand that you
                  have deadlines and reputation to keep... so we will meet ours!
                  You can rely on Ghanshyam Digital To handle your job expertly
                  every time.
                </p>
              </div>

              {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sagittis egestas ante, sed viverra nunc tincidunt nec nteger nonsed condimntum elit, sit amet feugiat lorem. Proin tempus sagittis velit vitae scelerisque.</p>*/}

              {/*<p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>*/}

              {/*<p>Business-to-business metrics analytics value proposition funding angel investor entrepreneur alpha ramen equity gamification. Social proof partner network research.</p>*/}
            </div>
          </div>
        </div>

        <div className="about-inner-area">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="about-text">
                <h3>Our History</h3>
                <p>
                  We have been a creative and competitive web developing company
                  since 2019 and been delivering great results and projects
                  while matching the asked deadlines of the project. Started
                  with the team of 2 to 10+ we're growing continuously.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="about-text">
                <h3>Our Mission</h3>
                <p>
                  Our mission is to provide and create the projects as per ones
                  vision with the utmost code quality forged with Passion and
                  Hard work.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="about-text">
                <h3>Who We Are</h3>
                <p>
                  An entrepreneur firm with clear vision and passion for the a
                  greater success for the company and the clients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/*<Team />*/}

    <Partner />

    <MilestonesAchievedSoFar />

    <Footer />
  </Layout>
)

export default About
